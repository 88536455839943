import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "viscon"
    }}>{`Viscon`}</h1>
    <h2 {...{
      "id": "project-outline"
    }}>{`Project outline`}</h2>
    <p>{`Viscon is a company that sells and distributes factory automisation. All data for customers and related projects, machines and parts is managed through RidderIQ (ERP system). HybrIT created a portal for suppliers / manufacturers of Viscon, in which the supplier / manufacturer can supply updates about the status of a project that is being build.`}</p>
    <h2 {...{
      "id": "architecture"
    }}>{`Architecture`}</h2>
    <p><img alt="Alt text" src={require("./blob/viscon-supplier-portal-architecture.png")} /></p>
    <h2 {...{
      "id": "coding--techniques"
    }}>{`Coding & techniques`}</h2>
    <ul>
      <li parentName="ul">{`Frontend build in NextJS (11.1.0)`}</li>
      <li parentName="ul">{`Backend API build .NET core (5.0)`}</li>
      <li parentName="ul">{`On-premise ridderIQ API build in .NET core (5.0), swagger docs included`}</li>
      <li parentName="ul">{`Docker & helm charts`}</li>
      <li parentName="ul">{`Using GitVersion to auto-version artifacts`}</li>
      <li parentName="ul">{`Azure DevOps pipeline definitions`}</li>
      <li parentName="ul">{`Using git secret to encrypt sensitive information like configuration files`}</li>
    </ul>
    <h2 {...{
      "id": "environments"
    }}>{`Environments`}</h2>
    <p>{`There is a semi (O)TP in place. Doing a git pull and running it locally will connect to the test environment. Due to the fact that viscon only has 1 test environment
for ridder and 1 production environment, we don't have a 'staging' env.`}</p>
    <p>{`The front-end runs at:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://test.supplierportal.viscon.hybrit.io"
        }}>{`https://test.supplierportal.viscon.hybrit.io`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://supplierportal.viscon.hybrit.io"
        }}>{`https://supplierportal.viscon.hybrit.io`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://supplierportal.viscon.eu"
        }}>{`https://supplierportal.viscon.eu`}</a></li>
    </ul>
    <p>{` The api runs at:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://api.test.supplierportal.viscon.hybrit.io"
        }}>{`https://api.test.supplierportal.viscon.hybrit.io`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://api.supplierportal.viscon.hybrit.io"
        }}>{`https://api.supplierportal.viscon.hybrit.io`}</a></li>
    </ul>
    <p>{` Keycloak runs at:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://auth.test.supplierportal.viscon.hybrit.io"
        }}>{`https://auth.test.supplierportal.viscon.hybrit.io`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://auth.supplierportal.viscon.eu"
        }}>{`https://auth.supplierportal.viscon.eu`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://auth.supplierportal.viscon.hybrit.io"
        }}>{`https://auth.supplierportal.viscon.hybrit.io`}</a></li>
    </ul>
    <p>{`--`}</p>
    <p>{` Connectionstrings, passwords and such can be found within the encrypted git secret files, which are part of the repositories.`}</p>
    <p>{`All environments run in one k8s cluster (there is no high demand for resources) seperated by namespaces. The kubernetes cluster runs in Azure and is named `}<inlineCode parentName="p">{`viscon-weu`}</inlineCode>{`. The k8s cluster is seeded with an nginx ingress controller and certificate manager. The cluster has a VPN tunnel to the network of viscon.`}</p>
    <h2 {...{
      "id": "repositories"
    }}>{`Repositories`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Repository`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`viscon-supplier-portal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`front end`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/viscon-supplier-portal"
            }}>{`https://github.com/HybrIT-dev/viscon-supplier-portal`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`viscon-supplier-portal-api-dotnet`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`back end`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/viscon-supplier-portal-api-dotnet"
            }}>{`https://github.com/HybrIT-dev/viscon-supplier-portal-api-dotnet`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`viscon-supplier-portal-keycloak`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`keycloak`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/viscon-supplier-portal-keycloak"
            }}>{`https://github.com/HybrIT-dev/viscon-supplier-portal-keycloak`}</a></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "getting-started-with-development"
    }}>{`Getting started with development`}</h2>
    <h3 {...{
      "id": "front-end"
    }}>{`Front-end`}</h3>
    <ol>
      <li parentName="ol">{`Copy the `}<inlineCode parentName="li">{`.env`}</inlineCode>{` file and name it `}<inlineCode parentName="li">{`.env-local`}</inlineCode></li>
      <li parentName="ol">{`Adjust the key/value pairs in the `}<inlineCode parentName="li">{`.env-local`}</inlineCode>{` file as needed`}</li>
      <li parentName="ol">{`Run `}<inlineCode parentName="li">{`yarn`}</inlineCode></li>
      <li parentName="ol">{`Run `}<inlineCode parentName="li">{`yarn dev`}</inlineCode></li>
      <li parentName="ol">{`Open `}<a parentName="li" {...{
          "href": "http://localhost:3000"
        }}>{`http://localhost:3000`}</a>{` with your browser`}</li>
    </ol>
    <h3 {...{
      "id": "backend"
    }}>{`Backend`}</h3>
    <p>{`To be able to communicate with the viscon API from your local machine, you'll need to install a VPN. The client used by viscon, is `}<a parentName="p" {...{
        "href": "https://www.fortinet.com/support/product-downloads#vpn"
      }}>{`Forticlient`}</a>{`. The password can be found in LastPass. You can use the following VPN settings:`}</p>
    <p><img alt="Alt text" src={require("./blob/viscon-vpn-settings.png")} /></p>
    <p>{`User: Markdi`}<br parentName="p"></br>{`
`}{`Server: access.viscongroup.eu, port 443`}</p>
    <ol>
      <li parentName="ol">{`Open the back-end solution in your favorite IDE`}</li>
      <li parentName="ol">{`Execute the command `}<inlineCode parentName="li">{`git secret reveal`}</inlineCode></li>
      <li parentName="ol">{`Run`}</li>
      <li parentName="ol">{`By default, the API connects to the test environment `}</li>
    </ol>
    <h2 {...{
      "id": "viscon-saleor-dev-docs"
    }}>{`Viscon Saleor Dev Docs`}</h2>
    <h3 {...{
      "id": "running-locally"
    }}>{`Running Locally:`}</h3>
    <p>{`The Viscon instance of Saleor will only run on Python 3.8 and several changes need to be made:
In Saleor core:`}</p>
    <ul>
      <li parentName="ul">{`libssl1.1 is outdated and needs to be replaced with libssl3`}</li>
      <li parentName="ul">{`in requirements_dev.txt codecov is outdated and needs to be removed.`}</li>
      <li parentName="ul">{`in saleor/settings.py comment/remove line 19 (AzureLogHandler)`}<ul parentName="li">
          <li parentName="ul">{`This is only used when running within Azure environments, and will break builds on other environments`}</li>
        </ul></li>
      <li parentName="ul">{`in saleor/settings.py when line 543 is not commented, it won't start locally.`}<ul parentName="li">
          <li parentName="ul">{`The VatlayerPlugin is no longer compatible, probably due to a dependency which no longer exists in dependency management for this version of Saleor`}</li>
        </ul></li>
      <li parentName="ul">{`Due to the changes to the database schema, a 'populatedb' cannot be run, therefore the command to run has been changed from what exists in the Repository README:`}<ul parentName="li">
          <li parentName="ul">{`instead of  `}<inlineCode parentName="li">{`docker-compose run --rm api python3 manage.py populatedb --createsuperuser`}</inlineCode>{` use `}<inlineCode parentName="li">{`docker-compose run --rm api python3 manage.py createsuperuser`}</inlineCode></li>
        </ul></li>
    </ul>
    <h3 {...{
      "id": "changes-from-default-saleor"
    }}>{`Changes from default saleor:`}</h3>
    <ul>
      <li parentName="ul">{`Projects added to products`}<ul parentName="li">
          <li parentName="ul">{`Graphql filter and search added for product projects`}</li>
        </ul></li>
      <li parentName="ul">{`Changes to bring products in line with Viscon Domain Model`}<ul parentName="li">
          <li parentName="ul">{`Added spare part boolean to product`}<ul parentName="li">
              <li parentName="ul">{`added to filter and search`}</li>
            </ul></li>
          <li parentName="ul">{`Added mainserviceobject link`}</li>
          <li parentName="ul">{`added relation link`}</li>
          <li parentName="ul">{`Added intermediary object called collectionproduct for linking`}</li>
          <li parentName="ul">{`added image url to product instead of whole image.`}</li>
          <li parentName="ul">{`added production job order to collection`}</li>
          <li parentName="ul">{`added build date to product.`}</li>
        </ul></li>
      <li parentName="ul">{`Changes to orders`}<ul parentName="li">
          <li parentName="ul">{`added quantity to fulfillmentline`}</li>
          <li parentName="ul">{`added quantity to orderline`}</li>
          <li parentName="ul">{`added quantity fulfilled to orderline`}</li>
        </ul></li>
    </ul>
    <h3 {...{
      "id": "viscon-azure-service-principal"
    }}>{`Viscon Azure Service Principal:`}</h3>
    <p>{`Viscon service principal credentials (client secret) heeft een expiration date. Om een nieuwe secret aan te maken volg deze stappen:`}</p>
    <ul>
      <li parentName="ul">{`Ga naar: `}<a parentName="li" {...{
          "href": "https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/Credentials/appId/9ac3ddb0-3b82-4e14-8692-5d558d5b01c2"
        }}>{`https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/Credentials/appId/9ac3ddb0-3b82-4e14-8692-5d558d5b01c2`}</a></li>
      <li parentName="ul">{`Voeg een nieuwe secret toe en kopieer de value`}</li>
      <li parentName="ul">{`Via azure CLI: `}<inlineCode parentName="li">{`az aks update-credentials --reset-service-principal --resource-group viscon-weu --name viscon-weu --service-principal 9ac3ddb0-3b82-4e14-8692-5d558d5b01c2 --client-secret <secret>`}</inlineCode></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      